import { useEffect, useRef, useState } from 'react';

import './index.scss'

import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { FileUpload } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Download, Editor, Header, Sidebar } from 'components';
import { useParams } from 'react-router-dom';
import { ProjectSingle } from 'utils/types';
import { projectService } from 'services';
import { ProgressSpinner } from 'primereact/progressspinner';

const Project = () => {
  const { id } = useParams()
  const [projectDetails, setProjectDetails] = useState<ProjectSingle>({} as ProjectSingle);
  const [loading, setLoading] = useState(false)

  useEffect(() => { id && getProject() }, [id])
  const getProject = () => {
    setLoading(true);
    projectService.getProjectDetails(id).then((data: ProjectSingle) => {
      if (data.id) {
        setProjectDetails(data)
      }
      // data.items && setProjects(data)
      setLoading(false);
    })
  }

  const toast = useRef<Toast>(null);
  const fileUploadRef = useRef<FileUpload>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const onTemplateSelect = (e: { files: File[] }) => {
    const newFiles = e.files;
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  // This function converts file to FormData and submits it to the API
  const uploadFiles = () => {
    if (selectedFiles.length === 0) return;
    setLoading(true);

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('file', file);  // Use 'files' if your API expects multiple files under one key
    });

    projectService.importTranslation(id, formData).then((data) => {
      if (!data.error) {
        toast.current?.show({ severity: 'success', summary: 'Success', detail: data.message || 'Files Uploaded' });
        fileUploadRef.current?.clear();
      } else {
        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Upload Failed' });
      }
      setLoading(false);
    });
  };


  const onTemplateClear = () => {
    setSelectedFiles([]);
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />

        <div className="flex align-items-center gap-3 ml-auto">
          {loading ? (
            <div className="loader">
              <ProgressSpinner />
            </div>
          ) : (
            <Button
              disabled={selectedFiles.length === 0}
              onClick={uploadFiles}
              icon="pi pi-fw pi-cloud-upload"
              className='custom-upload-btn p-button-success p-button-rounded p-button-outlined custom-width-button m-0'
            />)}

        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-upload mt-8" style={{ fontSize: '24px', borderRadius: '6px', padding: "12px", backgroundColor: 'var(--santas-gray)33', color: 'var(--rhino)' }}></i>
        <h4 className="mt-6 mb-2">Drop files here or click to upload</h4>
        <p className='mt-0 mb-8'>Supported files: zip, json</p>
      </div>
    );
  };
  const onBeforeDrop = (event: DragEvent) => {
    const dataTransfer = event.dataTransfer;

    if (!dataTransfer) return;

    const files = Array.from(dataTransfer.files);
    const validFiles = files.filter((file) => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      return fileExtension === 'zip' || fileExtension === 'json';
    });

    if (validFiles.length !== files.length) {
      toast.current?.show({
        severity: 'error',
        summary: 'Invalid File Type',
        detail: 'Only .zip and .json files are allowed.',
        life: 3000,
      });
      return false;
    }
  };

  const chooseOptions = { icon: 'pi pi-fw pi-file-import', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };

  return (
    <div className="flex h-screen">
      <Sidebar />

      <div className='dashboar-content project-content w-full'>
        <Header projectName={projectDetails.title} />

        <TabView className='bg-transparent'>
          <TabPanel header="Editor">
            <Editor />
          </TabPanel>


          <TabPanel header="Upload">
            <div className='flex justify-content-center upload-wrap'>
              <div>
                <h2 className="mb-1 text-center">Upload localization files</h2>
                <p className='mb-5 text-center'>You can upload single zip files.</p>
                <Toast ref={toast}></Toast>

                <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                <FileUpload
                  ref={fileUploadRef}
                  multiple
                  disabled={loading}
                  accept=".zip,.json" // Restrict to zip and json files
                  maxFileSize={100000000}
                  onSelect={onTemplateSelect}
                  onClear={onTemplateClear}
                  headerTemplate={headerTemplate}
                  emptyTemplate={emptyTemplate}
                  chooseOptions={chooseOptions}
                  onBeforeDrop={onBeforeDrop} // Add the onBeforeDrop event
                  style={{ width: '800px' }}
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel header="Download">
            <Download />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default Project;
